import {
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import CountrySelect from '../../components/CountrySelect';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import Title from '../../components/Title';
import { IVatFormItem } from '../../services/api/requests/vat-crud';
import pageLinks from '../../utils/pageLinks';
import { useVatForm } from './use-form';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

const CreateEditVat: FC = () => {
  const classes = useStandardFormStyles();

  const [{ isEdit, fk, options }] = useVatForm();
  const createEditText = isEdit ? 'Edit' : 'Create';
  const createInputField = createInput<IVatFormItem>(fk);
  const location = useLocation<{ prevPath: string }>();

  return (
    <Grid item xs={12}>
      <BackButton
        name={'Back'}
        link={location.state?.prevPath || pageLinks.payments}
        margin='0 0 10px 0'
      />
      <Paper className={classes.paper}>
        <Title>{createEditText} Vat </Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField('value', 'Value(percentage)', 6, 'text', true)}
              <Grid item xs={12}>
                <CountrySelect
                  id="countries"
                  value={fk.values.countries}
                  onChange={fk.setFieldValue}
                  error={!!(fk.errors.countries && fk.touched.countries)}
                  onBlur={fk.handleBlur}
                  withInstances
                  withGroups
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Gateways Methods</InputLabel>
                  <Select
                    id="gatewayMethods"
                    label={'Gateways Methods'}
                    variant="outlined"
                    multiple
                    value={fk.values.gatewayMethods}
                    onChange={(e) => fk.setFieldValue('gatewayMethods', e.target.value)}
                    input={<Input />}
                    renderValue={(selected: unknown): string =>
                      (selected as number[])
                        ?.map(
                          (id: number) => options.find((item) => item.value === id)?.label || ''
                        )
                        .join(', ')
                    }
                    MenuProps={MenuProps}
                  >
                    {options.map(({ value, label }) => (
                      <MenuItem className={classes.textWrap} key={label} value={value}>
                        <Checkbox checked={fk.values.gatewayMethods.includes(value)} />
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {createEditText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditVat;
