import {
  IMerchantFeeList,
  IMerchantFeeResponse,
  IProps,
} from '@root/components/MerchantFeeTable/types';
import {
  deleteMerchantFee,
  getMerchantFeeItem,
  getMerchantFeeList,
} from '@root/services/api/requests/merchant-fee-crud';
import { editMerchantFee } from '@root/store/entities/actions';
import { setSuccessNotification } from '@root/utils/notifications';
import pageLinks from '@root/utils/pageLinks';
import { AxiosError, AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useMerchantFee = (props: IProps) => {
  const [merchantFeeList, setMerchantFeeList] = useState<IMerchantFeeList[]>([]);
  const dispatch = useDispatch();
  const { loading, setLoading, setMeta, page, rowsPerPage } = props;

  const fetchMerchantList = async () => {
    try {
      const response: AxiosResponse<IMerchantFeeResponse> = await getMerchantFeeList({
        page,
        limit: rowsPerPage,
      });
      setLoading(false);
      setMerchantFeeList(response.data.data.items);
      setMeta({
        ...response.data.data.meta,
        page: Number(response.data.data.meta.page),
        limit: Number(response.data.data.meta.limit)
      });
    } catch (err) {
      console.error((err as AxiosError).message);
    }
  };

  const handleEditMerchantFee = async (merchantFeeId: number) => {
    try {
      const response = await getMerchantFeeItem(merchantFeeId);
      dispatch(editMerchantFee(response.data));
      dispatch(push(pageLinks.createEditMerchantFee));
    } catch (err) {
      console.error((err as AxiosError).message);
    }
  };

  const handleDeleteMerchantFee = async (id: number) => {
    try {
      await deleteMerchantFee(id);
      fetchMerchantList();
      setSuccessNotification();
    } catch (err) {
      console.error((err as AxiosError).message);
    }
  };

  useEffect(() => {
    dispatch(editMerchantFee(null));
  }, []);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }

    fetchMerchantList();
  }, [rowsPerPage, page]);

  const state = {
    loading,
    merchantFeeList,
  };

  const handlers = {
    handleEditMerchantFee,
    handleDeleteMerchantFee,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
