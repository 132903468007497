import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { FocusEventHandler } from 'react';
import { useSelector } from 'react-redux';
import createOptionList, { createCountryCodeList } from '../../hooks/instances';
import { ICountryGroup } from '../../services/api/requests/getCountries';
import { selectCountryGroups, selectInstances } from '../../store/entities/selectors';

interface CountryType {
  value(value: any): React.ReactNode;
  label: string;
}

interface Option {
  value: string;
  label: string;
}

function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== 'undefined' && isoCode.length === 2
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : '';
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

interface IProps {
  value: string[] | Array<{ code: string }> | string;
  id: string;
  withInstances?: boolean;
  disabled?: boolean;
  withGroups?: boolean;
  required?: boolean;
  onChange(a: string, b: string | string[], c?: boolean): void;
  error?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  helperText?: string;
  availableOptions?: string[] | null;
  multiple?: boolean;
  disableCloseOnSelect?: boolean;
  showGroupsAndCountriesTogether?: boolean;
  label?: string;
}

const CountrySelect: React.FC<IProps> = ({
  value,
  id,
  availableOptions,
  onChange,
  withInstances,
  withGroups,
  multiple = true,
  disableCloseOnSelect = false,
  label,
  disabled = false,
  showGroupsAndCountriesTogether = false,
  onBlur,
  ...rest
}) => {
  const classes = useStyles();
  const instances = useSelector(selectInstances);
  const groups = useSelector(selectCountryGroups);

  let val = value;
  // @ts-ignore
  if (Array.isArray(value) && value[0]?.code) {
    val = (value as Array<{ code: string }>).map((c) => c.code);
  }

  const { selected, options } = createOptionList(
    val as string | string[],
    Boolean(withInstances),
    availableOptions,
    !!withGroups,
    true
  );

  const onSelectChange = (e: any, selectedOptions: Option[] | Option) => {
    if (multiple) {
      const values: string[] = (selectedOptions as Option[]).map((item) => item.value);
      const { countries } = createCountryCodeList({
        selectedValues: values,
        instances: withInstances ? instances : undefined,
        groups: withGroups ? (groups as ICountryGroup[]) : undefined,
      });
      return onChange(id, countries);
    }
    return onChange(id, (selectedOptions as Option)?.value);
  };

  const selectedCountries = (Array.isArray(selected) ? selected : [selected])?.reduce(
    (acc: string[], item: any) => {
      if (typeof item?.value === 'number') {
        const countriesInInstance = groups
          ?.find((group) => +group.id === +item.value)
          ?.countries?.map((country) => country.code);
        acc = [...acc, ...(countriesInInstance || [])];
      } else if (item?.value) {
        acc.push(item.value);
      }

      return acc;
    },
    []
  );

  const checkIfSelected = (option: any) => {
    return selectedCountries.includes(option.value);
  };

  return (
    <Autocomplete
      id={id}
      options={options as CountryType[] | []}
      value={!multiple && Array.isArray(selected) ? selected[0] : selected}
      onChange={onSelectChange}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      multiple={multiple}
      disableCloseOnSelect={disableCloseOnSelect}
      fullWidth
      disabled={disabled}
      getOptionLabel={(option) => option.label}
      getOptionDisabled={checkIfSelected}
      renderOption={(option) => (
        <React.Fragment>
          <span>{countryToFlag(option.value)}</span>
          {option.label}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={rest.error}
          label={label || 'Choose a countries'}
          variant="outlined"
          fullWidth
          onBlur={onBlur}
          required={rest.required}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default CountrySelect;
