import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import BackButton from '@root/components/BackButton';
import CountrySelect from '@root/components/CountrySelect';
import { createInput, useStandardFormStyles } from '@root/components/FormBase';
import { PlansSelect } from '@root/components/PlansSelect';
import Title from '@root/components/Title';
import { useMerchantFeeForm } from '@root/pages/CreateMerchantFee/use-form';
import pageLinks from '@root/utils/pageLinks';
import { useLocation } from 'react-router-dom';
import InputSelect from '../../components/InputSelect';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

export const CreateMerchantFee = () => {
  const classes = useStandardFormStyles();
  const location = useLocation<{ prevPath: string }>();
  const { fk, isEdit, paymentMethodsOptions, actionsOptions } = useMerchantFeeForm();
  const title = isEdit ? 'Edit' : 'Create';
  const createEditText = isEdit ? 'Edit' : 'Create';
  const createInputField = createInput(fk);

  return (
    <Grid item xs={12}>
      <BackButton
        name="Back"
        link={location.state?.prevPath || pageLinks.payments}
        margin={'0 0 10px 0'}
      />

      <Paper className={classes.paper}>
        <Title>{title} Merchant Fee</Title>
        <div className={classes.formContainer}>
          <form onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              {createInputField('value', 'Value', 6, 'number')}
              <Grid item xs={12}>
                <CountrySelect
                  id="exclusionCountryIds"
                  value={fk.values.exclusionCountryIds as string[]}
                  onChange={fk.setFieldValue}
                  withInstances
                  withGroups
                />
              </Grid>
              <Grid item xs={12}>
                <PlansSelect
                  id="exclusionPlanIds"
                  value={fk.values.exclusionPlanIds}
                  onChange={fk.setFieldValue}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Gateways Methods</InputLabel>
                  <Select
                    id="exclusionGatewayMethodIds"
                    label="Gateways Methods"
                    variant="outlined"
                    multiple
                    value={fk.values.exclusionGatewayMethodIds}
                    onChange={(e) => fk.setFieldValue('exclusionGatewayMethodIds', e.target.value)}
                    input={<Input fullWidth />}
                    renderValue={(selected: unknown): string =>
                      (selected as number[])
                        ?.map(
                          (id: number) =>
                            paymentMethodsOptions?.find((item) => item.value === id)?.label || ''
                        )
                        .join(', ')
                    }
                    MenuProps={MenuProps}
                  >
                    {paymentMethodsOptions?.map(({ value, label }) => (
                      <MenuItem className={classes.textWrap} key={label} value={value}>
                        <Checkbox checked={fk.values.exclusionGatewayMethodIds.includes(value)} />
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputSelect
                  id="exclusionPurchaseActions"
                  label="Purchase Actions"
                  variant="outlined"
                  value={fk.values.exclusionPurchaseActions}
                  onChange={(e) => fk.setFieldValue('exclusionPurchaseActions', [e.target.value])}
                  options={actionsOptions}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {createEditText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};
