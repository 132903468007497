import {
  IInitialValue,
  IUpdateMerchantFee,
  MerchantFee,
} from '@root/components/MerchantFeeTable/types';
import { initialValue } from '@root/pages/CreateMerchantFee/static-data';
import {
  createMerchantFee,
  updateMerchantFee,
} from '@root/services/api/requests/merchant-fee-crud';
import { selectCountries, selectCountriesDictionary } from '@root/store/entities/selectors';
import { setSuccessNotification } from '@root/utils/notifications';
import pageLinks from '@root/utils/pageLinks';
import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { FormikProps, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

export const useMerchantFormik = (editMerchantFeeItem: MerchantFee | null) => {
  const dispatch = useDispatch();
  const countriesDictionary = useSelector(selectCountriesDictionary);
  const countries = useSelector(selectCountries);
  const selectedCountries = editMerchantFeeItem?.exclusionCountryIds.map(
    (item) => countriesDictionary[item].code
  );

  const fk: FormikProps<IInitialValue> = useFormik({
    initialValues: editMerchantFeeItem
      ? ({
          ...editMerchantFeeItem,
          exclusionCountryIds: selectedCountries,
        } as IInitialValue)
      : initialValue,
    onSubmit: async ({
      exclusionCountryIds: countriesCodes,
      ...rest
    }: IInitialValue | IUpdateMerchantFee) => {
      const exclusionCountryIds = countriesCodes
        .map((item) => {
          const country = countries.find((countryData) => countryData.code === item);
          return country ? country.id : null;
        })
        .filter((id): id is number => id !== null);

      const data = {
        ...rest,
        exclusionCountryIds,
      };
      try {
        if ('id' in rest) {
          await updateMerchantFee({ ...data, id: rest.id });
        } else {
          await createMerchantFee(data);
        }
        dispatch(push(pageLinks.payments));
        setSuccessNotification();
      } catch (err) {
        console.error((err as AxiosError).message);
      }
    },
  });

  return fk;
};
