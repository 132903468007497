import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { push } from 'connected-react-router';
import truncate from 'lodash/truncate';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VatApi, { IVatItem } from '../../services/api/requests/vat-crud';
import { IMeta } from '../../services/api/types';
import { editVatItem } from '../../store/entities/actions';
import { selectUserPermissions } from '../../store/system/selectors';
import { setSuccessNotification } from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import Can, { Permissions } from '../Can';
import { CustomTableHead, useStandardTableStyles } from '../DefaultTable';
import DeleteModalButton from '../DeleteModalButton';
import TableSkeleton from '../TableSkeleton';

const headCells = [
  {
    id: 'value',
    numeric: false,
    disablePadding: false,
    label: 'VAT percentage',
    disableSort: true,
  },
  { id: 'countries', numeric: false, disablePadding: false, label: 'Country', disableSort: true },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date created',
    disableSort: true,
  },
  {
    id: 'gatewayMethods',
    numeric: false,
    disablePadding: false,
    label: 'Payment methods',
    disableSort: true,
  },
];

const VatList: FC<{
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setMeta: (meta: IMeta) => void;
  page: number;
  rowsPerPage: number;
}> = (props) => {
  const classes = useStandardTableStyles();
  const dateFns = new DateFnsUtils();
  const permissions = useSelector(selectUserPermissions);
  const dispatch = useDispatch();

  const { loading, setLoading } = props;

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    if (permissions?.includes(Permissions.readVat)) {
      fetchList().catch(console.log);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [list, setList] = useState<IVatItem[] | null>(null);

  const fetchList = async () => {
    try {
      const response = await VatApi.getVatList();
      setLoading(false);
      setList(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handleUpdateVat = async (item: IVatItem) => {
    try {
      dispatch(editVatItem(item));
      dispatch(push(pageLinks.createVat));
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleDeleteVat = async (id: IVatItem['id']) => {
    try {
      await VatApi.deleteVatItem(id);
      setList((values) => (values as IVatItem[])?.filter((item) => item.id !== id));
      setSuccessNotification();
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <CustomTableHead headCells={headCells} classes={classes} />
        <TableBody>
          {loading && <TableSkeleton />}

          {!loading &&
            list?.map((item: IVatItem) => {
              const { id, value, countries, gatewayMethods, createdAt } = item;

              const create = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');

              return (
                <TableRow key={id}>
                  <TableCell align="left">{value}</TableCell>
                  <TableCell align="left">
                    <Box display="flex" flexWrap="wrap">
                      {countries.map((country, idx, arr) => (
                        <Tooltip title={country.name} key={country.code}>
                          <Box m={0.5} display="flex" flexWrap="wrap">{`${country.code}${
                            arr.length - 1 === idx ? '' : ', '
                          }`}</Box>
                        </Tooltip>
                      ))}
                    </Box>
                  </TableCell>
                  <TableCell align="left">{gatewayMethods.map((item) => item.name)}</TableCell>
                  <TableCell align="left">{create}</TableCell>

                  <TableCell align="center">
                    <Can perform={Permissions.updateVat}>
                      <IconButton
                        aria-label="edit"
                        title={'Edit'}
                        onClick={() => handleUpdateVat(item)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Can>
                  </TableCell>
                  <TableCell align="center">
                    <Can perform={Permissions.deleteVat}>
                      <DeleteModalButton
                        name={`${truncate(item.id.toString(), { length: 50 })}`}
                        entity={'faq item'}
                        onDelete={() => handleDeleteVat(id)}
                      />
                    </Can>
                  </TableCell>
                </TableRow>
              );
            })}

          {!loading && !list?.length && (
            <TableRow>
              <TableCell>There are no vat created</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VatList;
