import { IOption } from '@root/components/PlansSelect/types';
import { useMerchantFormik } from '@root/pages/CreateMerchantFee/use-formik';
import { selectEditMerchantFee, selectPaymentMethodList } from '@root/store/entities/selectors';
import { useSelector } from 'react-redux';

export const useMerchantFeeForm = () => {
  const merchantFeeForEditing = useSelector(selectEditMerchantFee);
  const paymentMethods = useSelector(selectPaymentMethodList);
  const isEdit = !!merchantFeeForEditing;
  const fk = useMerchantFormik(merchantFeeForEditing);
  useMerchantFormik(merchantFeeForEditing);
  const paymentMethodsOptions = paymentMethods?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const actionsOptions: IOption[] = [
    { value: 'RENEW', label: 'Renew' },
    { value: 'INITIAL', label: 'Buy' },
  ];

  const state = {
    isEdit,
    fk,
    paymentMethodsOptions,
    actionsOptions,
  };

  return state;
};
