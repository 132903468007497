import { createSelector } from '@reduxjs/toolkit';
import { getNameWithRecurringType } from '@root/components/CreateEditProduct/helpers';
import { sortBy } from 'lodash';
import groupBy from 'lodash/groupBy';
import { IPlanResponse } from '../../services/api/requests/editProductPlan';
import { ICountry, IGetInstance } from '../../services/api/requests/getInstances';
import { IPlan } from '../../services/api/requests/getProducts';
import { IService } from '../../services/api/requests/getServices';
import { normalizePlan } from '../../utils/planHelper';
import { RootState } from '../index';

const selectEntities = (state: RootState) => state.entities;

export const selectProducts = createSelector(selectEntities, (state) => state.products);

export const selectPlans = createSelector(selectProducts, (products) => {
  return products
    .map((product) => ({
      ...product,
      plans: product.plans.map((plan) => ({
        ...plan,
        productName: product.name,
        name: getNameWithRecurringType(plan.name, plan?.recurringType),
      })),
    }))
    .flatMap((product) => product.plans);
});

export const selectTLevelPlans = createSelector(selectProducts, (products) =>
  products
    .find((p) => p?.name === 'T-Level')
    ?.plans.map((plan) => ({
      ...plan,
      name: getNameWithRecurringType(plan.name, plan?.recurringType),
    }))
);

export const selectTLevelGroupOptions = createSelector(selectTLevelPlans, (products) => {
  return Object.values(groupBy(products, 'groupId'))
    ?.map((items) => {
      const item = items.sort((a, b) => a.name.length - b.name.length)[0];
      return { value: item.groupId, label: item.name };
    })
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
});

export const selectPermissions = createSelector(selectEntities, (state) => state.permissions);

export const selectCountriesWithoutInstances = createSelector(
  selectEntities,
  (state) => state.countriesWithoutInstances
);

export const selectPermissionGroups = createSelector(
  selectEntities,
  (state) => state.permissionGroups
);

export const selectAdminRoles = createSelector(selectEntities, (state) => state.roles);

export const selectInstances = createSelector(selectEntities, (state) => state.instances);

export const selectServices = createSelector(selectEntities, (state) => state.services);
export const selectUpsellingRulesServices = createSelector(
  selectEntities,
  (state) => state.upsellingRulesServices
);

export const selectSKUList = createSelector(selectEntities, (state) => state.skuList);

export const selectCountries = createSelector(selectEntities, (state) => state.countries);

export const selectCountriesDictionary = createSelector(selectEntities, (state) => {
  const data: { [key: string]: ICountry } = {};
  state.countries.map((item) => {
    data[item.id.toString()] = item;
  });
  return data;
});

export const selectCountryGroups = createSelector(selectEntities, (state) => state.countryGroups);
export const selectRecurringTypes = createSelector(selectEntities, (state) => state.recurringTypes);

export const selectCountryGroupOptions = createSelector(selectCountryGroups, (groups) => {
  if (groups) {
    return groups.map((group) => ({ name: group.name, code: group.name }));
  }
  return [];
});

export const selectCountryList = (withInstances: boolean, withGroups: boolean) =>
  createSelector(selectEntities, (state) => {
    if (state.instances.length) {
      const countries: any = {};
      countries.all = { name: 'All', code: 'all' };
      if (withInstances) {
        state.instances.forEach((instance) => {
          if (instance.countries.length && withInstances) {
            countries[instance.code] = { name: instance.name, code: instance.code };
          }
        });
      }
      if (withGroups && state.countryGroups) {
        state.countryGroups.forEach((gr) => {
          if (gr.countries.length) {
            countries[gr.id] = { name: gr.name, code: gr.id };
          }
        });
      }
      state.countries?.forEach((country: ICountry) => (countries[country.code] = country));
      return countries;
    }
    return state.countries;
  });

export const selectAdminRoleById = (id: number) =>
  createSelector(selectAdminRoles, (roles) => {
    if (roles) {
      return roles.find((role) => `${role.id}` === `${id}`);
    }
    return roles;
  });

export const selectPermissionGroupById = (id: number) =>
  createSelector(selectPermissionGroups, (groups) => {
    if (groups) {
      return groups.find((group) => `${group.id}` === `${id}`);
    }
    return groups;
  });

export const selectAvailablePlansOptions = createSelector(selectPlans, (plans) => {
  const all = plans.map((plan) => ({
    value: plan.id,
    label: `${plan.productName} ${plan.name || ''}`,
  }));

  const anyOption = { value: 'all', label: 'Any' };

  return [anyOption, ...all];
});

export const selectPlansOptionsValueID = createSelector(selectPlans, (plans) => {
  return sortBy(plans, 'name').map((plan) => ({
    value: plan.id,
    label: `${plan.productName} ${plan.name || ''}`,
  }));
});

export const selectPlansAsObjects = createSelector(selectPlans, (plans) => {
  return plans?.reduce((acc: Record<number, IPlan>, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
});

export const selectAvailablePlansOptionsById = (id: string) =>
  createSelector(selectPlans, (plans) => {
    return plans.map((plan) => ({
      value: plan[id as keyof typeof plan],
      label: `${plan.productName} ${plan.name || ''}`,
    }));
  });

export const selectServicesOptions = createSelector(selectServices, (serv: IService[]) => {
  return serv.map((s) => ({
    value: s.id,
    label: `${s.name} (exigoId: ${s.exigoId})`,
  }));
});

export const selectUserForEditing = createSelector(
  selectEntities,
  (entities) => entities.userForEditing
);

export const selectProductForEditing = createSelector(
  selectEntities,
  (entities) => entities.productForEditing
);

export const selectComboForEditing = createSelector(
  selectEntities,
  (entities) => entities.comboForEditing
);

export const selectProductCategoryForEditing = createSelector(
  selectEntities,
  (entities) => entities.productCategoryForEditing
);

export const selectCouponForEditing = createSelector(
  selectEntities,
  (entities) => entities.couponForEditing
);

export const selectCurrencyConverterForEditing = createSelector(selectEntities, (entities) => {
  const setting = entities.currencyConverterForEditing;
  if (setting) {
    return {
      id: setting?.id,
      enabled: setting.enabled,
      currencyCodeTo: setting.to.code,
      currencyCodeDefault: setting.from.code,
      feeAmount: setting.feeAmount,
      feeCurrency: setting.feeCurrency,
      round: setting.round,
    };
  }

  return setting;
});

export const selectComboPlanForEditing = createSelector(
  selectEntities,
  (entities) => entities.comboPlanForEditing
);

export const selectPlanForEditing = createSelector([selectEntities], (entities) => {
  if (entities.planForEditing) {
    const normalizedPlanData = normalizePlan(entities.planForEditing as IPlanResponse);
    return {
      formData: normalizedPlanData,
      id: entities.planForEditing.id,
      availableCountryCodes: entities.planForEditing.availableCountryCodes,
    };
  }
  return null;
});

export const selectInstanceForEditing = createSelector(
  selectEntities,
  (entities) => entities.instanceForEditing
);

export const selectMembershipRulesForEditing = createSelector(
  selectEntities,
  (entities) => entities.membershipRulesForEditing
);

export const selectSwitchSubscriptionRulesForEditing = createSelector(
  selectEntities,
  (entities) => entities.switchSubscriptionRulesForEditing
);

export const selectMembershipLevelForEditing = createSelector(
  selectEntities,
  (entities) => entities.membershipLevelForEditing
);

export const selectUmustlookItemForEditing = createSelector(
  selectEntities,
  (entities) => entities.umustlookItemForEditing
);

export const selectMindhubGroupForEditing = createSelector(
  selectEntities,
  (entities) => entities.mindhubGroupForEditing
);

export const selectNotificationForEditing = createSelector(
  selectEntities,
  (entities) => entities.notificationForEditing
);

export const selectFaqItemForEditing = createSelector(
  selectEntities,
  (entities) => entities.faqItemForEditing
);

export const selectCompanyInfoForEditing = createSelector(
  selectEntities,
  (entities) => entities.companyInfoForEditing
);

export const selectPaymentMethodList = createSelector(
  selectEntities,
  (entities) => entities.paymentMethods
);

export const selectPaymentMethodOptions = createSelector(selectPaymentMethodList, (list) => {
  if (list) {
    return list.map((item) => ({ label: item.name, value: item.id }));
  }
  return [];
});

export const selectGatewayForEditing = createSelector(
  selectEntities,
  (entities) => entities.gatewayForEditing
);

export const selectSettingForEditing = createSelector(
  selectEntities,
  (entities) => entities.settingForEditing
);

export const selectPaymentMethodForEditing = createSelector(
  selectEntities,
  (entities) => entities.paymentMethodForEditing
);

export const selectPromoDiscountForEditing = createSelector(
  selectEntities,
  (entities) => entities.promoDiscountForEditing
);

export const selectPayoutRequestList = createSelector(
  selectEntities,
  (entities) => entities.payoutRequestDetails
);

export const selectPayoutDetails = createSelector(
  selectEntities,
  (entities) => entities.payoutDetails
);

export const selectMembershipLevels = createSelector(
  selectEntities,
  (entities) => entities.membershipLevels
);

export const selectUmustlookItems = createSelector(
  selectEntities,
  (entities) => entities.umustlookItems
);

export const selectFaqItems = createSelector(selectEntities, (entities) => entities.faqItems);

export const selectCompanyInfoItems = createSelector(
  selectEntities,
  (entities) => entities.companyInfoItems
);

export const selectLegalDocumentForEditing = createSelector(
  selectEntities,
  (entities) => entities.legalDocumentForEditing
);

export const selectPurchaseRules = createSelector(
  selectEntities,
  (entities) => entities.purchaseRules
);

export const selectCouponUsageRules = createSelector(
  selectEntities,
  (entities) => entities.couponUsageRules
);

export const selectUserDetails = createSelector(selectEntities, (entities) => entities.userDetails);

export const selectServiceForEditing = createSelector(
  selectEntities,
  (entities) => entities.serviceForEditing
);

export const selectVatForEditing = createSelector(
  selectEntities,
  (entities) => entities.vatForEditing
);

export const selectInstanceOptionsValueID = createSelector(
  selectInstances,
  (instances: IGetInstance[]) => {
    if (instances.length) {
      return instances.map((i) => ({
        value: i.id,
        label: i.code,
      }));
    }

    return null;
  }
);

export const selectEditExchangeRateItem = createSelector(
  selectEntities,
  (entities) => entities.exchangeRateForEditing
);

export const selectEditMerchantFee = createSelector(
  selectEntities,
  (entities) => entities.merchantFeeForEditing
)
