import DateFnsUtils from '@date-io/date-fns';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Can, { Permissions } from '@root/components/Can';
import { CustomTableHead, useStandardTableStyles } from '@root/components/DefaultTable';
import DeleteModalButton from '@root/components/DeleteModalButton';
import { headCells } from '@root/components/MerchantFeeTable/static-data';
import { IProps } from '@root/components/MerchantFeeTable/types';
import { useMerchantFee } from '@root/components/MerchantFeeTable/use-merchant-fee-table';
import TableSkeleton from '@root/components/TableSkeleton';
import { truncate } from 'lodash';
import { FC } from 'react';

export const MerchantFeeTable: FC<IProps> = (props) => {
  const dateFns = new DateFnsUtils();
  const classes = useStandardTableStyles();
  const [{ loading, merchantFeeList }, { handleEditMerchantFee, handleDeleteMerchantFee }] =
    useMerchantFee(props);

  return (
    <TableContainer>
      <Table className={classes.table} size="medium">
        <CustomTableHead headCells={headCells} classes={classes} />
        <TableBody>
          {loading && <TableSkeleton />}

          {!loading &&
            merchantFeeList?.map((item) => {
              const { id, value, createdAt, updatedAt } = item;
              const create = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');
              const update = dateFns.format(new Date(updatedAt), 'dd/MM/yyyy hh:mm');

              return (
                <TableRow key={item.id}>
                  <TableCell align="left">{value}</TableCell>
                  <TableCell align="left">{create}</TableCell>
                  <TableCell align="left">{update}</TableCell>
                  <TableCell align="center">
                    <Can perform={Permissions.updateMerchantFee}>
                      <IconButton
                        aria-label="edit"
                        title="Edit"
                        onClick={() => handleEditMerchantFee(id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Can>
                  </TableCell>
                  <TableCell align="center">
                    <Can perform={Permissions.deleteMerchantFee}>
                      <DeleteModalButton
                        name={`${truncate(item.id.toString(), { length: 50 })}`}
                        entity={'faq item'}
                        onDelete={() => handleDeleteMerchantFee(id)}
                      />
                    </Can>
                  </TableCell>
                </TableRow>
              );
            })}

          {!loading && !merchantFeeList?.length && (
            <TableRow>
              <TableCell>There are no vat created</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
