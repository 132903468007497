import { IInitialValue } from "@root/components/MerchantFeeTable/types";
import { PAYMENT_METHOD_STATUSES } from "@root/services/api/requests/getPaymentMethodList";

export const initialValue: IInitialValue = {
  value: 0,
  exclusionPurchaseActions: [],
  exclusionCountryIds: [],
  exclusionGatewayMethodIds: [],
  exclusionPlanIds: [],
  status: PAYMENT_METHOD_STATUSES.ACTIVE,
};
