import {
  IUpdateMerchantFee,
  IInitialValue,
  IMerchantFeeResponse,
  MerchantFee,
} from '@root/components/MerchantFeeTable/types';
import ApiUrls from '@root/services/api/apiUrls';
import apiClient from '@root/services/api/client';
import { IPaginationParams } from '@root/services/api/types';
import { createQueryParams } from '@root/utils/helpers';
import { AxiosResponse } from 'axios';

export const getMerchantFeeList = (params: IPaginationParams) => {
  const query = createQueryParams(params);
  return apiClient
    .get<IMerchantFeeResponse>(ApiUrls.merchantFeeList + `?${query}`)
    .then((response) => response);
};

export const getMerchantFeeItem = (id: number) => {
  return apiClient
    .get<AxiosResponse<MerchantFee>>(ApiUrls.getMerchantFeeItem + `${id}`)
    .then((response) => response.data);
};

export const updateMerchantFee = (data: IUpdateMerchantFee) => {
  const { id, ...restData } = data;
  return apiClient
    .put(ApiUrls.updateMerchantFee + `${data.id}`, restData)
    .then((response) => response.data);
};

export const createMerchantFee = (data: IInitialValue) => {
  return apiClient.post(ApiUrls.createMerchantFee, data).then((response) => response);
};

export const deleteMerchantFee = (id: number) => {
  return apiClient.delete(ApiUrls.deleteMerchantFee + `${id}`).then((response) => response);
};
