import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { IOption, IPlansSelectedProps } from '@root/components/PlansSelect/types';
import { IPlan } from '@root/services/api/requests/getProducts';
import { selectPlansAsObjects, selectPlansOptionsValueID } from '@root/store/entities/selectors';
import { ChangeEvent, FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const PlansSelect: FC<IPlansSelectedProps> = ({
  value,
  id,
  multiple = true,
  disabled,
  label,
  onBlur,
  onChange,
  ...rest
}) => {
  const plansDictionaryById = useSelector(selectPlansAsObjects);
  const options = useSelector(selectPlansOptionsValueID);

  const selectedValue = useMemo(() => {
    const isArray = Array.isArray(value);
    let searchedPlan: IPlan | null = null;

    if (multiple && Array.isArray(value)) {
      return value.map((item) => {
        searchedPlan = plansDictionaryById[item];
        return {
          value: searchedPlan.id,
          label: searchedPlan.name,
        };
      });
    }

    if (!multiple && isArray) {
      searchedPlan = plansDictionaryById[value[0]];
      return {
        value: searchedPlan.id,
        label: searchedPlan.name,
      };
    }
    if (typeof value === 'number') {
      searchedPlan = plansDictionaryById[value];
      return {
        value: searchedPlan.id,
        label: searchedPlan.name,
      };
    }

    return null;
  }, [value]);

  const onSelectChange = (_: ChangeEvent<{}>, selectedOptions: IOption | IOption[] | null) => {
    if (multiple) {
      const values = (selectedOptions as IOption[]).map((option) => option.value as number);
      return onChange(id, values);
    }
    return onChange(id, (selectedOptions as IOption)?.value as number);
  };

  return (
    <Autocomplete
      id={id}
      options={options}
      value={selectedValue}
      onChange={onSelectChange}
      autoHighlight
      multiple={multiple}
      fullWidth
      disabled={disabled}
      getOptionLabel={(option) => option.label}
      renderOption={(option) => <span>{option.label}</span>}
      renderInput={(params) => (
        <TextField
          {...params}
          error={rest.error}
          label={label || 'Choose plans'}
          variant="outlined"
          fullWidth
          onBlur={onBlur}
          required={rest.required}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
        />
      )}
    />
  );
};
