import { createAction } from '@reduxjs/toolkit';
import { MerchantFee } from '@root/components/MerchantFeeTable/types';
import { IExchangeRate } from '@root/pages/ExchangeRateList/types';
import { IPromoDiscount } from '@root/services/api/requests/promo-discount';
import { IRecurringType } from '@root/services/api/requests/recurringTypes';
import { IDocumentBody } from '../../services/api/requests/addLegalDocument';
import { ICompanyInfo } from '../../services/api/requests/companyInfo';
import { ICreateCombo } from '../../services/api/requests/createComboPlan';
import { ICoupon } from '../../services/api/requests/createCoupon';
import { ICurrencyData } from '../../services/api/requests/currencyConverter';
import { IFaqItem } from '../../services/api/requests/faq';
import { IAdmin } from '../../services/api/requests/getAdminList';
import { IAdminRoles } from '../../services/api/requests/getAdminRoles';
import { IProductComboItem } from '../../services/api/requests/getComboProducts';
import { IConversionTemplate } from '../../services/api/requests/getConversionTemplatesList';
import { ICountryGroup } from '../../services/api/requests/getCountries';
import { ICountryWithoutInstances } from '../../services/api/requests/getCountriesWithoutInstances';
import { INormalizedCouponUsageRuleSet } from '../../services/api/requests/getCouponUsageRules';
import { INormalizedPayout } from '../../services/api/requests/getCustomerPayoutList';
import { IGateway } from '../../services/api/requests/getGatewayList';
import { ICountry, IGetInstance } from '../../services/api/requests/getInstances';
import { IPaymentMethod } from '../../services/api/requests/getPaymentMethodList';
import { IProductCategoryListItem } from '../../services/api/requests/getProductCategoryList';
import { IProductListItem } from '../../services/api/requests/getProductList';
import { IPlan } from '../../services/api/requests/getProductPlans';
import { IProduct } from '../../services/api/requests/getProducts';
import { INormalizedPurchaseRuleSet } from '../../services/api/requests/getPurchaseRules';
import { IService } from '../../services/api/requests/getServices';
import { ISetting } from '../../services/api/requests/getSettingsList';
import { ISKUItem } from '../../services/api/requests/getSKUList';
import { UserDetails } from '../../services/api/requests/getUserDetails';
import { IPayoutAdminRequestListItem } from '../../services/api/requests/massPayouts';
import { IMembershipLevel } from '../../services/api/requests/membershipLevels';
import { IMembershipRule } from '../../services/api/requests/membershipRules';
import { IMindhubGroup } from '../../services/api/requests/mindhubGroups';
import { INotification } from '../../services/api/requests/notifications';
import { IPermission, IPermissionGroup } from '../../services/api/requests/permissions';
import { IServiceItem } from '../../services/api/requests/services';
import { ISwitchSubscriptionRule } from '../../services/api/requests/switchSubscriptionRule';
import { IUmustlookItem } from '../../services/api/requests/umustlookItems';
import { IVatItem } from '../../services/api/requests/vat-crud';

export const setProducts = createAction<IProduct[]>('SET_PRODUCTS');
export const fetchStartData = createAction('FETCH_START_DATA');
export const updateProductsWithPlansList = createAction('UPDATE_PRODUCTS_WITH_PLANS_LIST');
export const setPermissions = createAction<IPermission[]>('SET_PERMISSIONS');
export const setPermissionGroups = createAction<IPermissionGroup[]>('SET_PERMISSION_GROUPS');
export const editProduct = createAction<IProductListItem | null>('EDIT_PRODUCT');
export const editProductCategory = createAction<IProductCategoryListItem | null>(
  'EDIT_PRODUCT_CATEGORY'
);
export const editAdminUser = createAction<IAdmin | null>('EDIT_ADMIN_USER');
export const setAdminRoles = createAction<IAdminRoles[]>('FETCH_ADMIN_ROLES_LIST_SUCCESS');
export const editCombo = createAction<IProductComboItem | null>('EDIT_COMBO');
export const editCouponData = createAction<ICoupon | null>('EDIT_COUPON');
export const editInstanceData = createAction<IGetInstance | null>('EDIT_INSTANCE');
export const editMembershipRulesData = createAction<IMembershipRule | null>(
  'EDIT_MEMBERSHIP_RULES'
);
export const editMembershipLevelData = createAction<IMembershipLevel | null>(
  'EDIT_MEMBERSHIP_LEVEL'
);
export const editUmustlookItemData = createAction<IUmustlookItem | null>('EDIT_UMUSTLOOK_ITEM');
export const editMindhubGroupData = createAction<IMindhubGroup | null>('EDIT_MINDHUB_GROUP');
export const editNotificationData = createAction<INotification | null>('EDIT_NOTIFICATION');
export const editFaqItemData = createAction<IFaqItem | null>('EDIT_FAQ_ITEM');
export const editCompanyInfoData = createAction<ICompanyInfo | null>('EDIT_COMPANY_INFO');
export const editGatewayData = createAction<IGateway | null>('EDIT_GATEWAY');
export const editSettingData = createAction<ISetting | null>('EDIT_SETTING');
export const editConversionTemplate = createAction<IConversionTemplate | null>(
  'EDIT_CONVERSION_TEMPLATE'
);
export const getPaymentMethodData = createAction<{ id: number }>('GET_PAYMENT_METHOD');
export const editPaymentMethodData = createAction<IPaymentMethod | null>('EDIT_PAYMENT_METHOD');
export const saveInstances = createAction<IGetInstance[] | []>('SAVE_INSTANCES');
export const saveCountries = createAction<ICountry[]>('SAVE_COUNTRIES');
export const setCountriesWithoutInstances = createAction<ICountryWithoutInstances[]>(
  'SET_COUNTRIES_WITHOUT_INSTANCES'
);
export const saveCountryGroups = createAction<ICountryGroup[]>('SAVE_COUNTRY_GROUPS');
export const editPlan = createAction<IPlan | null>('EDIT_PLAN');
export const editLegalDocument = createAction<IDocumentBody | null>(
  'SET_LEGAL_DOCUMENT_FOR_EDITING'
);
export const editComboPlan = createAction<ICreateCombo | null>('CREATE_COMBO_PLAN');
export const payoutDetails = createAction<INormalizedPayout | null>('PAYOUT_DETAILS');
export const fetchCountries = createAction('FETCH_COUNTRIES');
export const fetchCountriesWithoutInstances = createAction('FETCH_COUNTRIES_WITHOUT_INSTANCES');
export const fetchAdminRoles = createAction('FETCH_ADMIN_ROLES_LIST');
export const fetchPermissions = createAction('FETCH_PERMISSIONS');
export const updateInstanceList = createAction('UPDATE_INSTANCE_LIST');
export const saveServices = createAction<IService[]>('SAVE_SERVICES');
export const saveUpsellingRulesServices = createAction<IService[]>('SAVE_UPSELLING_RULES_SERVICES');
export const saveSKUList = createAction<ISKUItem[]>('SAVE_SKU_LIST');
export const savePaymentMethods = createAction<IPaymentMethod[]>('SAVE_PAYMENT_METHODS');
export const fetchUserDetails = createAction<number>('GET_USER_DETAILS');
export const setUserDetails = createAction<UserDetails>('SET_USER_DETAILS');
export const fetchMembershipLevels = createAction('GET_MEMBERSHIP_LEVELS');
export const setMembershipLevels = createAction<IMembershipLevel[]>('SET_MEMBERSHIP_LEVELS');
export const fetchUmustlookItems = createAction('GET_UMUSTLOOK_ITEMS');
export const setUmustlookItems = createAction<IUmustlookItem[]>('SET_UMUSTLOOK_ITEMS');
export const fetchMindhubGroups = createAction('GET_MINDHUB_GROUPS');
export const setMindhubGroups = createAction<IMindhubGroup[]>('SET_MINDHUB_GROUPS');
export const fetchNotifications = createAction('GET_NOTIFICATIONS');
export const setNotifications = createAction<INotification[]>('SET_NOTIFICATIONS');
export const fetchFaqItems = createAction('GET_FAQ_ITEMS');
export const setFaqItems = createAction<IFaqItem[]>('SET_FAQ_ITEMS');
export const fetchCompanyInfoItems = createAction('GET_COMPANY_INFO_ITEMS');
export const setCompanyInfoItems = createAction<ICompanyInfo[]>('SET_COMPANY_INFO_ITEMS');
export const showPayoutRequestInfo = createAction<IPayoutAdminRequestListItem[] | null>(
  'SHOW_PAYOUT_REQUEST_DETAILS'
);
export const syncServices = createAction('SYNC_SERVICES');

export const setPurchaseRules = createAction<INormalizedPurchaseRuleSet[] | []>(
  'SET_PURCHASE_RULES'
);

export const setCouponUsageRules = createAction<INormalizedCouponUsageRuleSet[] | []>(
  'SET_COUPON_USAGE_RULES'
);

export const fetchStartDataByUrl = createAction('FETCH_START_DATA_BY_URL');
export const editServiceData = createAction<IServiceItem | null>('EDIT_SERVICE');
export const editSwitchSubscriptionRulesData = createAction<ISwitchSubscriptionRule | null>(
  'EDIT_SWITCH_SUBSCRIPTION_RULES'
);
export const editVatItem = createAction<IVatItem | null>('EDIT_VAT_ITEM');
export const editPromoDiscount = createAction<IPromoDiscount | null>('EDIT_PROMO_DISCOUNT');

export const editCurrencyConverterItem = createAction<ICurrencyData | null>(
  'EDIT_CURRENCY_CONVERTER_ITEM'
);

export const setRecurringTypes = createAction<IRecurringType[] | null>('SET_RECURRING_TYPES');

export const editExchangeRate = createAction<IExchangeRate | null>('EDIT_EXCHANGE_RATE');
export const editMerchantFee = createAction<MerchantFee | null>('EDIT_MERCHANT_FEE');
