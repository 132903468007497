export const headCells = [
  {
    id: 'merchantFee',
    numeric: false,
    disablePadding: false,
    label: 'Merchant Fee',
    disableSort: true,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created at',
    disableSort: true,
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Updated at',
    disableSort: true,
  },
];
