import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CachedIcon from '@material-ui/icons/Cached';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import PlanPurchaseRules from '@root/components/PurchaseRules';
import BundleGroups from '@root/pages/BundleGroups';
import { CreateMerchantFee } from '@root/pages/CreateMerchantFee';
import { EditExchangeRate } from '@root/pages/EditExchangeRate';
import { GiftCardsReport } from '@root/pages/GiftCardsReport';
import PromoDiscounts from '@root/pages/PromoDiscounts';
import CreateEditPromoDiscount from '@root/pages/PromoDiscounts/create-edit-item';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import Can, { Permissions } from '../../components/Can';
import Copyright from '../../components/Copyright';
import CountriesNotification from '../../components/CountriesNotification';
import MenuCmp, { menuItems } from '../../components/Menu';
import { syncServices } from '../../store/entities/actions';
import { logOutUser } from '../../store/system/actions';
import pageLinks from '../../utils/pageLinks';
import AdminsLogs from '../AdminLogs';
import AdminPermission from '../AdminPermission';
import Admins from '../Admins';
import CompanyInfo from '../CompanyInfo';
import CountriesGroups from '../CountriesGroups';
import Coupons from '../Coupons';
import CreateCountriesGroups from '../CreateCountriesGroup';
import CreateCsvRequest from '../CreateCsvRequest';
import CreateAdmin from '../CreateEditAdmin';
import CreateEditCombo from '../CreateEditComboPlan';
import CreateEditComboProduct from '../CreateEditComboProduct';
import CreateEditCompanyInfoPage from '../CreateEditCompanyInfo';
import CreateEditConversionTemplates from '../CreateEditConversionTemplates';
import CreateCoupon from '../CreateEditCoupon';
import CreateEditCurrencyConverter from '../CreateEditCurrencyConverter';
import CreateEditFAQItem from '../CreateEditFAQItem';
import CreateEditGateway from '../CreateEditGateway';
import CreateEditInstance from '../CreateEditInstance';
import CreateEditLegalDocument from '../CreateEditLegalDocument';
import CreateEditMembershipLevelPage from '../CreateEditMembrshipLevel';
import CreateEditMembershipRules from '../CreateEditMembrshipRules';
import CreateEditMindhubGroup from '../CreateEditMindhubGroup';
import CreateEditMoneySpending from '../CreateEditMoneySpending';
import CreateEditNotification from '../CreateEditNotification';
import CreateEditPaymentMethod from '../CreateEditPaymentMethod';
import CreateEditPlan from '../CreateEditPlan';
import CreateEditProduct from '../CreateEditProduct';
import CreateEditProductCategory from '../CreateEditProductCategory';
import CreateEditService from '../CreateEditService';
import CreateEditSetting from '../CreateEditSetting';
import CreateEditSwitchSubscriptionRule from '../CreateEditSwitchSubscriptionRule';
import CreateEditUmustlookItem from '../CreateEditUmustlookItem';
import CreateEditVat from '../CreateEditVat';
import CreatePermissionGroup from '../CreatePermissionGroup';
import CreatePermissionRole from '../CreatePermissionRole';
import CreateWhiteLIstIp from '../CreateWhiteListIp';
import CurrencyConverter from '../CurrencyConverter';
import CustomerPayouts from '../CustomerPayouts';
import CustomersLogs from '../CustomersLogs';
import DeclineOrders from '../DeclineOrders';
import ExchangeRateList from '../ExchangeRateList';
import FAQList from '../FAQList';
import InstallmentsOrders from '../Installmentsorders';
import Instances from '../Instances';
import Invoices from '../Invoices';
import KYCList from '../KYCList';
import LegalDocuments from '../LegalDocuments';
import ManageCustomerBundles from '../ManageCustomerBundles';
import MembershipSettings from '../MembershipSettings';
import MindhubGroups from '../MindhubGroups';
import MoneySpending from '../MoneySpending';
import NestedRulesPage from '../NestedRulesPage';
import Notifications from '../Notifications';
import Orders from '../Orders';
import Payments from '../Payments';
import PayoutDetails from '../PayoutDetails';
import CSVs from '../PayoutExport';
import Products from '../Products';
import ProductsActivations from '../ProductsActivations';
import RecurringTypes from '../RecurringTypes';
import SageRoles from '../SageRoles';
import Services from '../Services';
import Settings from '../Settings';
import SignedDocuments from '../SignedDocuments';
import SwitchSubscriptionRules from '../SwitchSubscriptionRules';
import TriggerEvents from '../TriggerEvents';
import UmustlookSettings from '../UmustlookSettings';
import UserKYCReport from '../UserKYCReport';
import UserMigrationsReport from '../UserMigrationsReport';
import WhiteList from '../WhiteList';
import useStyles from './styles';
// import { MerchantFee } from "@root/components/MerchantFee";

export default function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const openMenu = Boolean(anchorEl);

  const [search, setSearch] = useState('');

  // const {
  //   location: { pathname },
  // } = useSelector(selectRouter);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const moveToPage = (route: string): void => {
    dispatch(push(route));
    setSearch('');
  };

  const listWithSearch = React.useMemo(() => {
    if (search.length) {
      return menuItems
        .map((item) => ({
          ...item,
          name: item.name,
          subCategory: item.subCategory.filter((subItem) =>
            subItem.name.toLowerCase().includes(search.toLowerCase())
          ),
        }))
        .filter((item) => item.subCategory.length);
    }

    return menuItems;
  }, [search]);

  // const downMenuWithSearch = useMemo(() => {
  //   if (search.length) {
  //     return downMenu.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
  //   }
  //   return downMenu;
  // }, [search]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleMenu = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const onSyncServiceClick = () => {
    dispatch(syncServices());
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleLogOut = () => {
    dispatch(logOutUser());
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Box className={classes.boxSearch}>
            <SearchIcon />
            <InputBase
              placeholder={'Search…'}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              inputProps={{ 'aria-label': 'search' }}
              fullWidth
            />
          </Box>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {/*Dashboard*/}
          </Typography>
          <div>
            <Button onClick={onSyncServiceClick} color="inherit">
              <CachedIcon />
              <Typography component="h6">Sync Data</Typography>
            </Button>

            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <MenuCmp
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
        moveToPage={moveToPage}
        listWithSearch={listWithSearch}
        open={open}
      />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <CountriesNotification />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Switch>
              <Route exact path={pageLinks.home} component={Products} />
              <Route exact path={pageLinks.products} component={Products} />
              <Route exact path={pageLinks.admins} component={Admins} />
              <Route exact path={pageLinks.orders} component={Orders} />
              <Route exact path={pageLinks.declineOrders} component={DeclineOrders} />
              <Route exact path={pageLinks.createEditAdmin} component={CreateAdmin} />
              <Route exact path={pageLinks.coupons} component={Coupons} />
              <Route exact path={pageLinks.createEditCoupon} component={CreateCoupon} />
              <Route exact path={pageLinks.installmentsOrders} component={InstallmentsOrders} />
              <Route exact path={pageLinks.instances} component={Instances} />
              <Route exact path={pageLinks.payments} component={Payments} />
              <Route exact path={`${pageLinks.payments}/:tabIndex`} component={Payments} />
              <Route exact path={pageLinks.moneySpending} component={MoneySpending} />
              <Route exact path={pageLinks.currencyConverter} component={CurrencyConverter} />
              <Route exact path={pageLinks.recurringTypes} component={RecurringTypes} />
              <Route exact path={pageLinks.promoDiscounts} component={PromoDiscounts} />
              <Route exact path={pageLinks.bundleGroups} component={BundleGroups} />
              <Route exact path={pageLinks.purchaseRules} component={PlanPurchaseRules} />
              <Route
                exact
                path={pageLinks.createPromoDiscount}
                component={CreateEditPromoDiscount}
              />
              <Route
                exact
                path={pageLinks.createEditCurrencyConverter}
                component={CreateEditCurrencyConverter}
              />
              <Route
                exact
                path={pageLinks.createEditMoneySpending}
                component={CreateEditMoneySpending}
              />
              <Route
                exact
                path={pageLinks.createEditSwitchSubscriptionRule}
                component={CreateEditSwitchSubscriptionRule}
              />
              <Route
                exact
                path={pageLinks.bundleSwitchSubscriptionRule}
                component={SwitchSubscriptionRules}
              />
              <Route exact path={pageLinks.manageUserBundle} component={ManageCustomerBundles} />
              <Route exact path={pageLinks.settings} component={Settings} />
              <Route exact path={pageLinks.services} component={Services} />
              <Route exact path={pageLinks.createEditService} component={CreateEditService} />
              <Route exact path={pageLinks.addCsvReport} component={CreateCsvRequest} />
              <Route exact path={pageLinks.permissions} component={AdminPermission} />
              <Route exact path={pageLinks.createEditProduct} component={CreateEditProduct} />
              <Route exact path={pageLinks.createEditSetting} component={CreateEditSetting} />
              <Route exact path={pageLinks.createEditGateway} component={CreateEditGateway} />
              <Route exact path={pageLinks.nestedRules} component={NestedRulesPage} />
              {/* <Route exact path={pageLinks.massPaymentRequests} component={PayoutRequests} /> */}
              <Route exact path={pageLinks.legalDocuments} component={LegalDocuments} />
              <Route exact path={pageLinks.countriesGroups} component={CountriesGroups} />
              <Route exact path={pageLinks.customersLogs} component={CustomersLogs} />
              <Route exact path={pageLinks.adminsLogs} component={AdminsLogs} />
              <Route exact path={pageLinks.membershipSettings} component={MembershipSettings} />
              <Route exact path={pageLinks.triggerEvents} component={TriggerEvents} />
              <Route exact path={pageLinks.sageRoles} component={SageRoles} />
              <Route exact path={pageLinks.giftCardsReport} component={GiftCardsReport} />
              <Route
                exact
                path={pageLinks.createEditMembershipRules}
                component={CreateEditMembershipRules}
              />
              <Route
                exact
                path={pageLinks.createEditMembershipLevel}
                component={CreateEditMembershipLevelPage}
              />
              <Route
                exact
                path={pageLinks.createCountriesGroup}
                component={CreateCountriesGroups}
              />
              <Route
                exact
                path={pageLinks.editCountriesGroup + '/:groupId'}
                component={CreateCountriesGroups}
              />
              <Route exact path={pageLinks.mindhubGroups} component={MindhubGroups} />
              <Route
                exact
                path={pageLinks.createEditMindhubGroup}
                component={CreateEditMindhubGroup}
              />
              <Route exact path={pageLinks.notifications} component={Notifications} />
              <Route
                exact
                path={pageLinks.createEditNotification}
                component={CreateEditNotification}
              />
              <Route exact path={pageLinks.productsActivations} component={ProductsActivations} />
              <Route exact path={pageLinks.umustlookSettings} component={UmustlookSettings} />
              <Route
                exact
                path={pageLinks.createEditUmustlookItem}
                component={CreateEditUmustlookItem}
              />
              <Route exact path={pageLinks.companyInfo} component={CompanyInfo} />
              <Route
                exact
                path={pageLinks.createEditCompanyInfo}
                component={CreateEditCompanyInfoPage}
              />
              <Route exact path={pageLinks.createEditFaqItem} component={CreateEditFAQItem} />
              <Route
                exact
                path={pageLinks.createEditLegalDocument}
                component={CreateEditLegalDocument}
              />
              <Route
                exact
                path={pageLinks.createEditPaymentMethod}
                component={CreateEditPaymentMethod}
              />
              <Route exact path={pageLinks.createEditInstance} component={CreateEditInstance} />
              <Route exact path={pageLinks.createEditPlan} component={CreateEditPlan} />
              <Route exact path={pageLinks.createEditCombo} component={CreateEditCombo} />
              <Route
                exact
                path={pageLinks.createEditComboProduct}
                component={CreateEditComboProduct}
              />
              <Route
                exact
                path={pageLinks.createEditProductCategory}
                component={CreateEditProductCategory}
              />

              <Route exact path={pageLinks.csvReport} component={CSVs} />
              <Route exact path={pageLinks.customerPayouts} component={CustomerPayouts} />
              <Route exact path={pageLinks.userMigrationsReport} component={UserMigrationsReport} />
              <Route exact path={pageLinks.userKycReport} component={UserKYCReport} />
              <Route exact path={pageLinks.payoutDetails} component={PayoutDetails} />
              <Route exact path={pageLinks.whiteList} component={WhiteList} />
              <Route exact path={pageLinks.createWhiteListIp} component={CreateWhiteLIstIp} />
              <Route
                exact
                path={pageLinks.createEditConversionTemplate}
                component={CreateEditConversionTemplates}
              />
              <Route exact path={pageLinks.signedDocuments} component={SignedDocuments} />
              <Route exact path={pageLinks.invoices} component={Invoices} />
              <Route exact path={pageLinks.kyc} component={KYCList} />
              <Route exact path={pageLinks.faqList} component={FAQList} />

              {/* <Route
                exact
                path={pageLinks.massPayoutRequestDetails}
                component={MassPayoutDetails}
              /> */}

              <Route
                exact
                path={pageLinks.createEditPermissionRole + '/:roleId'}
                component={CreatePermissionRole}
              />

              <Route
                exact
                path={pageLinks.createEditPermissionRole}
                component={CreatePermissionRole}
              />

              <Route
                exact
                path={pageLinks.createEditPermissionGroup + '/:groupId'}
                component={CreatePermissionGroup}
              />

              <Route
                exact
                path={pageLinks.createEditPermissionGroup}
                component={CreatePermissionGroup}
              />

              <Route exact path={pageLinks.createVat} component={CreateEditVat} />

              <Route exact path={pageLinks.editExchangeRate} component={EditExchangeRate} />

              {/* <Route exact path={pageLinks.merchantFee} component={MerchantFee} /> */}

              <Can perform={Permissions.createMerchantFee}>
                <Route exact path={pageLinks.createEditMerchantFee} component={CreateMerchantFee} />
              </Can>

              <Can
                perform={Permissions.readPointsExchangeList}
                otherwise={<Redirect to={pageLinks.home} />}
              >
                <Route exact path={pageLinks.exchangeRateList} component={ExchangeRateList} />
              </Can>

              <Can
                perform={Permissions.readUsersList}
                no={() => (
                  <Switch>
                    <Redirect to={pageLinks.home} />
                  </Switch>
                )}
              >
                <Route exact path={pageLinks.admins} component={Admins} />
              </Can>
            </Switch>
          </Grid>

          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
