import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserPermissions } from '../../store/system/selectors';

export const Permissions = {
  createUsers: 'create_users',
  createAdminRole: 'create_admin_role',
  updateAdmin: 'update_admin',
  updateAdminRole: 'update_admin_role',
  deleteAdminRole: 'delete_admin_role',
  deleteCoupon: 'delete_coupon',
  createCoupon: 'create_coupon',
  updateCoupon: 'update_coupon',
  createAdmin: 'create_admin',
  deleteAdmin: 'delete_admin',
  exchangeRateAdmin: 'exchange_rate_admin',
  deleteSubscriptionPlan: 'delete_subscription_plan',
  readProductList: 'read_product_list',
  createProduct: 'create_product',
  updateProduct: 'update_product',
  readSubscriptionPlanList: 'read_subscription_plan_list',
  updateAdmins: 'update_admins',
  deleteAdmins: 'delete_admins',
  readAdminList: 'read_admin_list',
  createSubscriptionPlan: 'create_subscription_plan',
  readCouponReport: 'read_coupon_report',
  updateSubscriptionPlan: 'update_subscription_plan',
  readAdminRoleList: 'read_admin_role_list',
  readPermissionList: 'read_permission_list',
  readUserList: 'read_user_list',
  updateServices: 'update_services',
  createService: 'create_service',
  deleteService: 'delete_service',
  readService: 'read_service',
  readServiceList: 'read_service_list',
  readPlansList: 'read_a_list_of_subscription_plans',
  getRoleList: 'get_admin_role_list',
  getPermissionList: 'get_permission_list',
  readUsersList: 'read_a_list_of_users',
  readInstanceList: 'read_instance_list',
  createInstance: 'create_instance',
  updateInstance: 'update_instance',
  deleteInstance: 'delete_instance',
  createPaymentGateway: 'create_payment_gateway',
  readPayments: 'read_payment_gateway_and_payment_method_lists',
  readSettings: 'read_settings',
  updateSettings: 'update_setting',
  updateSettingValue: 'update_setting_value',
  createSettings: 'create_setting',
  deleteSettings: 'delete_setting',
  enablePhoneVerification: 'enable_phone_verification',
  updatePaymentGateway: 'update_payment_gateway',
  updatePaymentMethod: 'update_payment_method',
  deletePaymentGateway: 'delete_payment_gateway',
  deleteExportsHistoryItem: 'delete_exports_history_item',
  downloadingCsvFiles: 'downloading_csv_files',
  generateUsersPayouts: 'generate_users_payouts',
  exportPayoutCsv: 'export_payout_csv',
  exportPayoutList: 'export_payout_list',
  createPlanUpgradeRules: 'create_plan_upgrade_rules',
  updatePlanUpgradeRules: 'update_plan_upgrade_rules',
  deletePlanUpgradeRules: 'delete_plan_upgrade_rules',
  viewProduct: 'view_product',
  deleteProduct: 'delete_product',
  readCustomerList: 'read_customer_list',
  generateUserMigrationsReport: 'generate_migrated_users_report',
  generateUserKycReport: 'kyc_verification_report',

  createPurchaseRules: 'create_purchase_rules',
  createRequiredPlanOfPurchaseRules: 'create_required_plan_of_purchase_rules',
  readPurchaseRules: 'read_purchase_rules',
  updateRequiredPlanOfPurchaseRules: 'update_required_plan_of_purchase_rules',
  deleteRequiredPlanOfPurchaseRules: 'delete_required_plan_of_purchase_rules',
  deletePurchaseRules: 'delete_purchase_rules',

  createLegalDocuments: 'create_legal_documents',
  readLegalDocuments: 'read_legal_documents',
  updateLegalDocuments: 'update_legal_documents',
  deleteLegalDocuments: 'delete_legal_documents',

  readOrderList: 'read_order_list',

  createCouponUsageRules: 'create_coupon_usage_rule',
  createRequiredPlanOfCouponUsageRules: 'create_required_plan_of_coupon_usage_rule',
  readCouponUsageRules: 'read_coupon_usage_rules',
  updateRequiredPlanOfCouponUsageRules: 'update_required_plan_of_coupon_usage_rule',
  deleteRequiredPlanOfCouponUsageRules: 'delete_required_plan_of_coupon_usage_rule',
  deleteCouponUsageRules: 'delete_coupon_usage_rule',

  expandIpWhitelist: 'expand_ip_whitelist',
  readIpWhitelist: 'read_ip_whitelist',
  deleteIpFromWhitelist: 'delete_ip_from_whitelist',

  massPayoutList: 'read_mass_payout_list',
  downloadSignedDocs: 'download_signed_docs',

  createCountriesGroup: 'create_country_group',
  editCountriesGroup: 'update_country_group',
  deleteCountriesGroup: 'delete_country_group',

  readCustomersLogs: 'read_logs',
  readAdminsLogs: 'read_logs',

  verifyOrderItems: 'verify_order_items',

  readConversionTemplateList: 'read_conversion_template_list',
  createConversionTemplate: 'create_conversion_template',
  readConversionTemplate: 'read_conversion_template',
  updateConversionTemplate: 'update_conversion_template',
  deleteConversionTemplate: 'delete_conversion_template',
  readSegment: 'read_segment',

  readUpgradeMembershipRuleList: 'read_upgrade_membership_rule_list',
  readUpgradeMembershipRule: 'read_upgrade_membership_rule',
  createUpgradeMembershipRule: 'create_upgrade_membership_rule',
  updateUpgradeMembershipRule: 'update_upgrade_membership_rule',
  deleteUpgradeMembershipRule: 'delete_upgrade_membership_rule',

  readMembershipLevelList: 'read_membership_level_list',
  readMembershipLevel: 'read_membership_level',
  createMembershipLevel: 'create_membership_level',
  updateMembershipLevel: 'update_membership_level',
  deleteMembershipLevel: 'delete_membership_level',

  readSupportApprovalOrderList: 'read_support_approval_order_list',
  paySupportApprovalRequest: 'pay-support-approval-request',

  readBaseSpending: 'read_base_spending',
  createBaseSpending: 'create_base_spending',
  updateBaseSpending: 'update_base_spending',

  readUmustlookItem: 'read_umustlook_item',
  createUmustlookItem: 'create_umustlook_item',
  updateUmustlookItem: 'update_umustlook_item',
  deleteUmustlookItem: 'delete_umustlook_item',

  readFaqList: 'read_faq_list',
  readFaqItem: 'read_faq',
  createFaqItem: 'create_faq',
  updateFaqItem: 'update_faq',
  deleteFaqItem: 'delete_faq',

  readKycVerificationListLevel1: 'get_kyc_verification_list_level1',
  updateKycVerificationListLevel1: 'update_kyc_verification_level1',
  readKycVerificationListLevel2: 'get_kyc_verification_list_level2',
  updateKycVerificationListLevel2: 'update_kyc_verification_level2',
  startVerificationManually: 'start_verification_manually',
  // GET /kyc/settings without permissions
  createKycVerificationSettings: 'create_kyc_verification_settings',
  updateKycVerificationSettings: 'update_kyc_verification_settings',
  deleteKycVerificationSettings: 'delete_kyc_verification_settings',

  readCompanyInfoList: 'read_company_info_list',
  createCompanyInfo: 'create_company_info',
  updateCompanyInfo: 'update_company_info',
  deleteCompanyInfo: 'delete_company_info',

  createVat: 'create-vat',
  readVat: 'read-vat',
  updateVat: 'update-vat',
  deleteVat: 'delete-vat',

  createCurrencyConverter: 'create-currency-converter-setting',
  readCurrencyConverterList: 'read-currency-converter-setting-list',
  updateCurrencyConverter: 'update-currency-converter-setting',
  deleteCurrencyConverter: 'delete-currency-converter-setting',

  deleteSwitchingRules: 'delete_switching_rules',
  createSwitchingRules: 'create_switching_rules',
  readSwitchingRules: 'read_switching_rules',
  updateSwitchingRules: 'update_switching_rules',

  readMindhubGroup: 'read_mindhub_group',
  createMindhubGroup: 'create_mindhub_group',
  updateMindhubGroup: 'update_mindhub_group',
  deleteMindhubGroup: 'delete_mindhub_group',

  readNotifications: 'read-shop-notification-list',
  createNotification: 'create-shop-notification',
  updateNotification: 'update-shop-notification',
  deleteNotification: 'delete-shop-notification',

  readSwitchSubscriptionRules: 'read_switch_subscription_rules',

  readPromoDiscountList: 'read_promo_discount',
  createPromoDiscount: 'create_promo_discount',
  deletePromoDiscount: 'delete_promo_discount',
  updatePromoDiscount: 'update_promo_discount',

  readRecurringType: 'read_recurring_type',
  deleteRecurringType: 'delete_recurring_type',
  createRecurringType: 'create_recurring_type',
  updateRecurringType: 'update_recurring_type',

  readBundleGroupList: 'read_bundle_group_list',
  deleteBundleGroup: 'delete_bundle_group',
  createBundleGroup: 'create_bundle_group',
  updateBundleGroup: 'update_bundle_group',

  readPointsExchangeList: 'read-points-exchange-list',
  updatePointsExchange: 'update-points-exchange',
  deletePointsExchange: 'delete-points-exchange',

  readSageRoleList: 'read_sage_role_list',
  createSageRole: 'create_sage_role',
  readSageRole: 'read_sage_role',
  updateSageRole: 'update_sage_role',
  deleteSageRole: 'delete_sage_role',

  // merchant fee
  readMerchantFeeList: 'read_merchant_fee_list',
  createMerchantFee: 'create_merchant_fee',
  readMerchantFee: 'read_merchant_fee',
  updateMerchantFee: 'update_merchant_fee',
  deleteMerchantFee: 'delete_merchant_fee',
};

// const allPermissions = [
//   'create_user',
//   'read_product',
//   'create_product',
//   'read_subscription_plan_list',
//   'delete_subscription_plan',
//   'update_subscription_plan',
//   'delete_product',
//   'read_user_list',
//   'create_admin',
//   'update_admin',
//   'read_admin_list',
//   'read_product_list',
//   'update_user',
//   'update_product',
//   'read_coupon_report',
//   'create_coupon',
//   'delete_admin',
//   'delete_coupon',
//   'delete_admin_role',
//   'create_admin_role',
//   'update_permission_group',
//   'create_permission_group',
//   'read_permission_list',
//   'create_subscription_plan',
//   'read_admin_role_list',
//   'update_admin_role',
//   'update_coupon',
//   'delete_user',
//   'read_country_list',
//   'create_country_group',
//   'read_coupon',
//   'update_country_group',
//   'read_instance_list',
//   'update_instance',
//   'delete_instance',
//   'delete_country_group',
//   'create_instance',
//   'delete_permission_group',
//   'read_region_list',
//   'update_region',
//   'read_upselling_rules',
//   'read_payment_gateway_and_payment_method_lists',
//   'update_plan_status',
//   'delete_region',
//   'get_payment_method',
//   'update_payment_method',
//   'update_payment_gateway',
//   'create_region',
//   'create_upselling_rule',
//   'update_upselling_rule',
//   'create_mass_payout',
//   'validate_mass_payout',
//   'create_legal_document',
//   'validate_d_local_payout',
//   'read_mass_payout_list',
//   'update_product_status',
//   'delete_upselling_rule',
//   'create_required_plan_of_purchase_rule',
//   'read_legal_documents',
//   'create_coupon_usage_rule',
//   'delete_purchase_rule',
//   'create_purchase_rule',
//   'read_purchase_rules',
//   'create_d_local_payout',
//   'create_required_plan_of_coupon_usage_rule',
//   'delete_legal_document',
//   'update_legal_document',
//   'update_required_plan_of_coupon_usage_rule',
//   'delete_required_plan_of_coupon_usage_rule',
//   'delete_coupon_usage_rule',
//   'update_required_plan_of_purchase_rule',
//   'read_settings',
//   'delete_required_plan_of_purchase_rule',
//   'update_setting',
//   'update_setting_value',
//   'read_coupon_usage_rules',
//   'seed_content_items',
//   'seed_user_roles',
//   'seed_permission_groups',
//   'create_setting',
//   'seed_mha_courses',
//   'seed_global_settings',
//   'delete_setting',
//   'seed_initial_data',
//   'expand_ip_whitelist',
//   'read_ip_whitelist',
//   'seed_mha_categories',
//   'seed_mha_subcategories',
//   'seed_mha_languages',
//   'verify_order_items',
//   'enable_phone_verification',
//   'delete_service',
//   'generate_migrated_users_report',
//   'upload_users_migrations',
//   'update_services',
//   'delete_ip_from_whitelist',
//   'read_customer_list',
//   'create_service',
//   'read_service_list',
//   'seed_instances',
//   'read_service',
//   'read_order_list',
//   'add_picture',
//   'test_email',
//   'download_signed_docs',
//   'get_kyc_verification_list_level1',
//   'synchronize_third_party',
//   'update_kyc_verification_level1',
//   'create_conversion_region',
//   'get_kyc_verification_list_level2',
//   'create_kyc_verification_settings',
//   'read_conversion_region_list',
//   'delete_kyc_verification_settings',
//   'read_conversion_template',
//   'delete_attachment',
//   'update_membership_level',
//   'read_product_category',
//   'update_kyc_verification_settings',
//   'update_base_spending',
//   'delete_switching_rules',
//   'delete_umustlook_item',
//   'read_logs',
//   'update_conversion_region',
//   'delete_membership_level',
//   'delete_product_category',
//   'read_conversion_template_list',
//   'read_attachment',
//   'create_umustlook_item',
//   'create_upgrade_membership_rule',
//   'update_upgrade_membership_rule',
//   'create_conversion_template',
//   'create_attachment',
//   'read_membership_level',
//   'read_umustlook_item',
//   'update_product_category',
//   'update_conversion_template',
//   'read_conversion_region',
//   'create_switching_rules',
//   'delete_conversion_region',
//   'update_umustlook_item',
//   'read_upgrade_membership_rule',
//   'delete_upgrade_membership_rule',
//   'update_kyc_verification_level2',
//   'read_membership_level_list',
//   'read_base_spending',
//   'create_product_category',
//   'delete_conversion_template',
//   'create_membership_level',
//   'update_switching_rules',
//   'read_upgrade_membership_rule_list',
//   'create_base_spending',
//   'read_switching_rules',
//   'read_product_category_list',
//   'export_payout_list',
//   'export_payout_csv',
//   'generate_users_payouts',
//   'downloading_csv_files',
//   'delete_exports_history_item',
// ];

interface IProps {
  children: React.ReactNode;
  perform?: string;
  no?: (a: void) => any;
  otherwise?: React.ReactNode;
}

const Can: React.FC<IProps> = (props) => {
  const { perform, no, children, otherwise = '' } = props;
  const availablePermissions = useSelector(selectUserPermissions);

  if (!perform) {
    return children;
  }

  if (!availablePermissions) {
    return no ? no() : <div />;
  }

  // @ts-ignore
  const can = availablePermissions?.includes(perform);

  return can ? children : otherwise;
};

export default Can;
